import React from "react";
import { Navbar, Footer, CookieConsent, Announcement } from "components";
import { LayoutProps } from "types/MainLayout";
import { GoogleTagManager } from "@next/third-parties/google";
import Head from "next/head";
import { toast } from "react-toastify";
import { useHasNewDeploy } from "hooks/useHasNewDeploy";
import { useRouter } from "next/router";

interface Props extends LayoutProps {
  dashboardMode?: boolean;
}
const MainLayout = ({ children, title, dashboardMode }: Props) => {
  const router = useRouter();

  const { hasNewDeploy } = useHasNewDeploy({
    interval: 30000,
    debug: false,
    endpoint: "/api/has-new-deploy",
  });

  if (hasNewDeploy) {
    toast.info("New version is available! Please refresh the page.", {
      autoClose: false,
      onClick: () => router.reload(),
      toastId: "new-deploy",
    });
  }

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <CookieConsent />
      <GoogleTagManager gtmId="AW-16518531409" />

      <Announcement
        title={
          <span>✨ Introducing Pay as You Go with ₳DA Payment Support</span>
        }
        href="https://blog.blockfrost.io/pay-as-you-go/"
      />

      <Navbar dashboardMode={dashboardMode} />
      {children}
      <Footer />
      <style jsx>{`
        .tada {
          margin: 0px 6px;
        }
      `}</style>
    </>
  );
};

export { MainLayout };
