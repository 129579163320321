import { Copy, Check } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  textToCopy?: string;
  size?: number;
}

function IconCopyToClipboard({ textToCopy, size = 15, ...rest }: Props) {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <div {...rest}>
      {copied ? (
        <Check size={size} />
      ) : (
        <CopyToClipboard
          text={textToCopy || ""}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 500);
          }}
        >
          <Copy size={size} role="button" style={{ cursor: "pointer" }} />
        </CopyToClipboard>
      )}
    </div>
  );
}

export { IconCopyToClipboard };
